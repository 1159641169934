







































import { Component, Vue } from "vue-property-decorator";

@Component
export default class ForgotPasswordPassword extends Vue {
  password = "";
  errorMsg = "";
  loading = false;

  async next(): Promise<void> {
    this.loading = true;
    try {
      await this.$store.dispatch(
        "forgotPassword/setNewPassword",
        this.password
      );
      this.$router.push({ name: "forgot-password-confirm" });
    } catch (error) {
      this.errorMsg = error as string;
    } finally {
      this.loading = false;
    }
  }
}
